import React, { forwardRef, useImperativeHandle, useRef } from "react"
import { useSelector } from "react-redux"

import Modal from "../../Layout/Modal"
import bannerAplicativo from "../../../images/banner-aplicativo.png"
import IconWhatsApp from "../../../images/icons/whatsapp-icon.svg"

const CheckoutSuccess = forwardRef(({ orderSaved }, ref) => {
  const establishmentInfo = useSelector(state => state.order.establishment)
  const modalRef = useRef()

  const showModal = () => {
    modalRef.current.showModal()
  }

  const closeModal = () => {
    modalRef.current.closeModal()
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      showModal()
    },
    closeModal() {
      closeModal()
    },

    isOpened() {
      return modalRef.current.isOpened()
    },
  }))

  const renderContent = () => (
    <div className="overflow-y-auto">
      <div className="flex flex-col lg:flex-row items-center h-full ">
        <div className="p-5 flex flex-col items-center h-full ">
          <p className="text-primary text-2xl lg:text-3xl">
            Agradecemos o seu Pedido
          </p>
          <div className="pt-8">
            <p className="text-center">
              Seu pedido{" "}
              <strong className="text-primary font-bold">
                #{orderSaved?.id_order_delivery}
              </strong>{" "}
              foi recebido com sucesso e está aguardando pela nossa aprovação!
            </p>
            <p className="text-center pt-8">
              Em caso de dúvidas ou problemas com seu pedido,{" "}
              <br className="hidden lg:block" />
              entre em contato o estabelecimento{" "}
              <strong className="text-primary font-bold">
                {establishmentInfo?.establishment_name}
              </strong>
              :
            </p>
          </div>
          <div className="pt-8 flex items-center">
            <a
              href={`https://web.whatsapp.com/send/?phone=55${establishmentInfo?.celphone}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={IconWhatsApp}
                className="w-9"
                alt="Enviar mensagem no WhatsApp"
              />
            </a>
            <span className="px-5">ou</span>
            <span className="text-xl font-semibold py-2 px-4 bg-light-grey text-dark-grey rounded">
              {establishmentInfo?.celphone}
            </span>
          </div>
        </div>
        <div className="flex mt-5 justify-center items-center flex-col md:flex-row bg-light-grey rounded">
          <a
            className="flex flex-1 mt-5 md:mt-0"
            href="https://baixar.queropedir.com.br"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={bannerAplicativo}
              className="w-full object-fill rounded-2xl"
              alt="Baixe o QueroPedir"
            />
          </a>
        </div>
      </div>
    </div>
  )

  return (
    <Modal
      ref={modalRef}
      padding={0}
      showCloseButton={false}
      canCloseModal={false}
    >
      {renderContent()}
    </Modal>
  )
})

export default CheckoutSuccess
