import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react"
import ProductAdditionalOptionsList from "../ProductAdditionalOptionsList"

const ProductAdditional = forwardRef(
  ({ additional, changeCurrentPrice, updateAdditionalsPrice }, ref) => {
    const [additionalInfo, setAdditionalInfo] = useState({})

    useEffect(() => {
      const auxAdditional = additional

      // CLEAN STATE
      auxAdditional.optionals = auxAdditional.optionals.map(option => {
        option.quantity = 0
        return option
      })

      if (auxAdditional.type === 1 || auxAdditional.type === 4) {
        auxAdditional.optionals[0].quantity = 1
      }

      setAdditionalInfo(auxAdditional)
    }, [])

    useImperativeHandle(ref, () => ({
      getAdditionalInfo() {
        return additionalInfo
      },
    }))

    const renderOptionalQuantityMessage = () => {
      const {
        type,
        quantity_choice: quantityChoice,
        quantity_choice_min: quantityChoiceMin,
      } = additional

      if (type === 1 || (quantityChoice === 0 && quantityChoiceMin === 0)) {
        // SINGLE CHOICE
        return null
      }

      if (quantityChoice === quantityChoiceMin) {
        if (quantityChoice === 1) {
          return "Escolha uma opção"
        }
        return `Escolha ${quantityChoice} opções`
      }

      if (quantityChoiceMin > 0) {
        return `Escolha no mínimo ${quantityChoiceMin} e no máximo ${quantityChoice} opções`
      }

      return `Escolha até ${quantityChoice} opções`
    }

    const onChange = (index, updateType = null) => {
      const addtionalUpdate = additionalInfo

      if (additionalInfo.type === 1) {
        addtionalUpdate.optionals = addtionalUpdate.optionals.map(option => {
          option.quantity = 0
          return option
        })
        addtionalUpdate.optionals[index].quantity = 1
      }

      if (additionalInfo.type === 2) {
        addtionalUpdate.optionals[index].quantity =
          addtionalUpdate.optionals[index].quantity === 1 ? 0 : 1
      }

      if (additionalInfo.type === 3) {
        if (!updateType) {
          return
        }
        const currentQuantity = addtionalUpdate.optionals[index].quantity

        if (updateType === "increase") {
          addtionalUpdate.optionals[index].quantity = currentQuantity + 1
        }
        if (
          updateType === "decrease" &&
          addtionalUpdate.optionals[index].quantity > 0
        ) {
          addtionalUpdate.optionals[index].quantity = currentQuantity - 1
        }
      }

      if (additionalInfo.type === 4) {
        addtionalUpdate.optionals = addtionalUpdate.optionals.map(option => {
          option.quantity = 0
          return option
        })
        addtionalUpdate.optionals[index].quantity = 1
        changeCurrentPrice(addtionalUpdate.optionals[index].price)
      }

      setAdditionalInfo(addtionalUpdate)
      updateAdditionalsPrice()
    }
    return (
      <div className="md:grid md:grid-cols-3 md:grid-flow-col md:gap-6 w-full">
        <div>
          <p className="text-lg text-primary break-words">
            {additionalInfo.client_title}
          </p>
          <p className="text-dark-grey">{additionalInfo.client_message}</p>
          {renderOptionalQuantityMessage() ? (
            <p className="bg-primary rounded-xl xl:rounded-full text-center text-white font-bold text-sm py-1 mt-2 md:px-3 lg:px-0">
              {renderOptionalQuantityMessage()}
            </p>
          ) : null}
        </div>
        <div className="col-span-2">
          <ProductAdditionalOptionsList
            optionals={additionalInfo.optionals}
            type={additionalInfo.type}
            onChange={(index, updateType = null) => onChange(index, updateType)}
          />
        </div>
      </div>
    )
  }
)

export default ProductAdditional
