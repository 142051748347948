import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { formatBR2 } from "../../../helpers/Functions"
import Spinner from "../Spinner"

const ProductListItem = ({ product, showProductDetailModal }) => {
  const {
    app_name: appName,
    price,
    picture,
    short_description: shortDescription,
    priceFrom,
  } = product
  const [imageLoaded, setImageLoaded] = useState(false)
  const [imageDimension, setImageDimension] = useState({ width: 0, height: 0 })
  const establishmentInfo = useSelector(state => state.order.establishment)

  let productPicture = establishmentInfo.logo

  if (picture && picture?.length > 0) {
    productPicture = picture
  }

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const img = new Image()

    img.src = productPicture
    img.onload = () => {
      setImageDimension({
        width: img.width,
        height: img.height,
      })
      setImageLoaded(true)
    }
  }, [])

  return (
    <button
      key={Math.random().toString(36).substring(2)}
      className="flex w-full flex-col lg:flex-row rounded overflow-hidden h-auto lg:h-32 shadow-md"
      type="button"
      onClick={() => {
        if (showProductDetailModal) showProductDetailModal(product)
      }}
    >
      <div className="w-full lg:w-[128px] sm:h-32 h-full flex items-center max-h-40">
        {imageLoaded ? (
          <img
            src={productPicture}
            className={`w-full lg:w-auto lg:max-w-[128px] h-full m-auto max-h-40 ${
              imageDimension.height > imageDimension.width
                ? "object-contain lg:object-fill"
                : "object-cover"
            }
              ${productPicture !== picture ? "opacity-25" : ""}
            `}
            alt={appName}
          />
        ) : (
          <Spinner />
        )}
      </div>
      <div className="p-4 w-auto sm:w-full lg:w-auto flex-auto flex-col justify-between text-left leading-normal">
        <p
          className="font-bold break-normal line-clamp-2 break-all"
          title={appName}
        >
          {appName}
        </p>
        <p
          className="text-dark-grey overflow-hidden line-clamp-1 break-all"
          title={shortDescription}
        >
          {shortDescription}
        </p>
        <p className="text-primary pt-2">{`${
          priceFrom ? "à partir de " : ""
        }R$ ${formatBR2(price)}`}</p>
      </div>
    </button>
  )
}

export default ProductListItem
