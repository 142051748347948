/* eslint-disable no-undef */
/* eslint-disable no-alert */
import React, { createRef, useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAngleRight,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import { formatBR2, generateRandomString } from "../../../helpers/Functions"
import ProductAdditional from "../ProductAdditional"
import Spinner from "../Spinner"
import { addProduct } from "../../../store/actions/order"
import IngredientsList from "../Ingredients"
import HeaderNavigation from "../../Layout/HeaderNavigation"

const ProductInfo = ({ product, closeModal }) => {
  const {
    app_name: appName,
    price,
    picture,
    long_description: longDescription,
    additionals,
    ingredients,
  } = product
  const [imageLoaded, setImageLoaded] = useState(false)
  const [imageDimension, setImageDimension] = useState({ width: 0, height: 0 })
  const [currentPrice, setCurrentPrice] = useState(price)
  const [quantity, setQuantity] = useState(1)
  // const [obs, setObs] = useState("")
  const [additonalsComponent, setAdditonalsComponent] = useState([])
  const additonalsComponentRef = useRef([])
  const [additonalsPrice, setAdditonalsPrice] = useState(0)
  const dispatch = useDispatch()
  const isOpened = useSelector(state => state.order.establishment.is_opened)

  let productPicture = null
  if (picture && picture?.length > 0) {
    productPicture = picture
  }

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const img = new Image()
    img.src = picture
    img.onload = () => {
      setImageDimension({
        width: img.width,
        height: img.height,
      })
      setImageLoaded(true)
    }

    const additonalsComponentList = []

    additonalsComponentRef.current = additionals.map(
      (_, i) => additonalsComponentRef.current[i] ?? createRef()
    )

    const updateAdditionalsPrice = () => {
      let aditionalPriceAux = 0

      additionals.forEach(additional => {
        if (additional.type === 4) return
        additional.optionals.forEach(option => {
          if (option.quantity > 0) {
            aditionalPriceAux += option.quantity * option.price
          }
        })
      })

      console.log(aditionalPriceAux)
      setAdditonalsPrice(aditionalPriceAux)
    }

    additionals.forEach((additional, index) => {
      additonalsComponentList.push(
        <div className="card items-start" key={additional.id}>
          <ProductAdditional
            ref={additonalsComponentRef.current[index]}
            additional={additional}
            changeCurrentPrice={setCurrentPrice}
            updateAdditionalsPrice={updateAdditionalsPrice}
          />
        </div>
      )
    })
    setAdditonalsComponent(additonalsComponentList)
  }, [])

  const increaseQuantity = () => {
    setQuantity(quantity + 1)
  }

  const decreaseQuantity = () => {
    if (quantity === 1) return

    setQuantity(quantity - 1)
  }

  const renderAdditonals = () =>
    additonalsComponent.map(additionalComponent => additionalComponent)

  const renderIngredients = () => {
    if (ingredients.length === 0) {
      return null
    }

    return (
      <div className="card items-start">
        <IngredientsList ingredients={ingredients} />
      </div>
    )
  }

  const addItem = () => {
    // CHECK IF HAVE ANY ADDITIONAL REQUIRED NOT FILLED
    const checkAdditionals = []
    product.additionals.forEach(addic => {
      let quantityAlreadyChoiced = 0
      addic.optionals.forEach(option => {
        if (option.quantity > 0) {
          quantityAlreadyChoiced += option.quantity
        }
      })
      addic.quantityAlreadyChoiced = quantityAlreadyChoiced
      if (
        addic.quantity_choice_min > 0 &&
        addic.quantityAlreadyChoiced < addic.quantity_choice_min
      ) {
        checkAdditionals.push(addic)
      }
    })

    if (checkAdditionals.length > 0) {
      const addic = checkAdditionals[0]
      if (addic.quantityAlreadyChoiced < addic.quantity_choice_min)
        alert(
          `${addic.client_title} - Escolha ao mínimo ${addic.quantity_choice_min} `
        )

      return
    }

    product.quantity = quantity
    product.price = currentPrice
    product.additonalsPrice = additonalsPrice
    product.total = product.quantity * (product.price + product.additonalsPrice)
    product.cart_id = generateRandomString()

    dispatch(addProduct(product))
    closeModal()
  }

  return (
    <>
      <HeaderNavigation
        goBack={() => {
          closeModal()
        }}
        title="Detalhes do Produto"
        marginBottom={0}
      />
      <div className="lg:grid lg:grid-cols-5 overflow-y-scroll bg-white">
        <div className="col-span-2">
          <div className="card">
            {productPicture && (
              <div className="rounded">
                {imageLoaded ? (
                  <img
                    src={picture}
                    className={`${
                      imageDimension.height > imageDimension.width
                        ? "object-contain"
                        : "object-fill"
                    } max-h-28 md:max-h-36`}
                    alt={appName}
                  />
                ) : (
                  <Spinner />
                )}
              </div>
            )}
            <p className="text-2xl pt-6 pb-2 text-primary break-normal">
              {appName}
            </p>
            <p className="text-dark-grey break-normal">{longDescription}</p>
          </div>
        </div>
        <div className="col-span-3">
          {renderIngredients()}
          {renderAdditonals()}
          <div className="flex flex-col lg:flex-row justify-between items-center p-6">
            <div className="flex justify-center items-center">
              <div className="flex flex-row justify-center items-center rounded-full bg-light-grey">
                <button
                  type="button"
                  onClick={() => decreaseQuantity()}
                  className="rounded-full w-8 h-8 m-2 text-center pt-0.5 text-dark-grey transition-colors hover:bg-primary hover:text-white"
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <p className="text-primary text-xl px-1 py-3 select-none">
                  {quantity} un
                </p>
                <button
                  type="button"
                  onClick={() => increaseQuantity()}
                  className="rounded-full w-8 h-8 m-2 text-center pt-0.5 text-dark-grey transition-colors hover:bg-primary hover:text-white"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>
            <div className="card m-0 mt-2 lg:mt-0">
              <p className="text-primary text-lg px-3 select-none">
                <span className="lg:hidden text-black text-sm">Total </span>R${" "}
                {formatBR2((currentPrice + additonalsPrice) * quantity)}
              </p>
            </div>
          </div>
          <div className="flex px-4 py-8 select-none">
            <button
              type="button"
              className="rounded-full text-white bg-primary px-4 py-3 flex-1 transition duration-300 shadow-md hover:shadow-xl disabled:opacity-40"
              onClick={addItem}
              disabled={!isOpened}
            >
              <span className="text-base lg:text-xl">ADICIONAR AO PEDIDO</span>
              <FontAwesomeIcon icon={faAngleRight} className="ml-2 mb-0.5" />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductInfo
