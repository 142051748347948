import React from "react"
import "./style.scss"

const Footer = () => (
  <footer className="p-4 bg-primary">
    <div className="flex flex-col lg:flex-row items-center container mx-auto ">
      <div className="w-full lg:w-auto lg:mr-auto text-center lg:text-left text-white">
        <strong>QueroPedir</strong>
      </div>
      <div className="flex justify-center items-center space-x-6 mt-4 lg:mt-0 text-white">
        <a href="https://systemcode.com.br" target="_blank" rel="noreferrer">
          Desenvolvido por <strong>SystemCode</strong>
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
