/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from "react"
// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-unresolved

import { useDispatch, useSelector } from "react-redux"

import SEO from "../../Layout/Seo"
import CategoryList from "../CategoryList"
import ProductList from "../ProductList"
import Cart from "../Cart"
import ProductInfoModal from "../ProductInfoModal"

import {
  checkDeliveryRegion,
  loadEstablishmentInfo,
  loadMenuEstabilishment,
} from "../../../helpers/apiCalls"
import {
  setDeliveryInfo,
  setEstablishmentOrder,
  setIpInfo,
} from "../../../store/actions/order"
import EstablishmentInfo from "../EstablishmentInfo"
import Spinner from "../Spinner"
import { getUserIpInfo } from "../../../helpers/Functions"
import { setCurrentCategory } from "../../../store/actions/category"

const Establishment = ({ navigate, establishment }) => {
  const [establishmentInfo, setEstablishmentInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [catolog, setCatolog] = useState([])
  const [isLoadingCatolog, setIsLoadingCatolog] = useState(false)
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const productModalRef = useRef()
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.user.user)

  const loadCatalog = async id => {
    setIsLoadingCatolog(true)

    await loadMenuEstabilishment(id)
      .then(async response => {
        setCatolog(response.data)

        // SET CATEGORIES
        const subCate = []
        const cate = []
        response.data.map(c =>
          c.categories.map(category => {
            cate.push({
              id: category.id,
              name: category.name,
            })
            subCate.push(...category.subcategories)
            return null
          })
        )
        setCategories(cate)
        dispatch(setCurrentCategory(cate[0].id))
        setSubCategories(subCate)
        setIsLoadingCatolog(false)
      })
      .catch(() => {
        setIsLoadingCatolog(false)
        setCatolog(null)
      })
  }

  const loadData = async () => {
    const savedAddress = currentUser?.currentAddress
      ? currentUser?.currentAddress
      : null

    setIsLoading(true)

    getUserIpInfo().then(ipInfo => {
      dispatch(setIpInfo(ipInfo))
    })

    await loadEstablishmentInfo(
      establishment,
      savedAddress ? savedAddress.regionKey : "999"
    )
      .then(async response => {
        if (response.success) {
          setEstablishmentInfo(response.data)
          dispatch(setEstablishmentOrder(response.data))
          checkDeliveryRegion(
            savedAddress ? savedAddress.regionKey : "999",
            response.data.id
          ).then(responseDeliveryInfo => {
            dispatch(setDeliveryInfo(responseDeliveryInfo.data))
          })
          loadCatalog(response.data.id)
        } else {
          setEstablishmentInfo([])
        }
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        setEstablishmentInfo(null)
      })
  }

  useEffect(() => {
    loadData()
    const confirmExit = () => {
      if (productModalRef.current.isOpened()) {
        productModalRef.current.closeModal()
        navigate(`#principal`, { replace: true })
      }
    }
    window.addEventListener("popstate", confirmExit)
    return () => {
      window.removeEventListener("popstate", confirmExit)
    }
  }, [])

  const renderCategories = () => (
    <div className="container mx-auto">
      <CategoryList categories={categories} />
    </div>
  )

  const renderCatalog = () => {
    if (isLoadingCatolog) {
      return (
        <div className="container mx-auto m-5 text-center flex">
          <Spinner />
        </div>
      )
    }

    if (!catolog)
      return (
        <div>
          Falha ao buscar estabelecimento... Por favor, verifique a sua conexão
        </div>
      )

    if (catolog.length === 0)
      return <div>Nenhum estabelecimento localizado...</div>

    return null
  }

  const showProductDetailModal = product => {
    productModalRef.current.setProduct(product)
    productModalRef.current.showModal(true)
  }

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="container mx-auto m-5 text-center flex">
          <Spinner />
        </div>
      )
    }

    if (!establishmentInfo)
      return (
        <div>
          Falha ao buscar estabelecimento... Por favor, verifique a sua conexão
        </div>
      )

    if (establishmentInfo.length === 0)
      return <div>Nenhum estabelecimento localizado...</div>

    return (
      <div className="md:flex-initial md:w-3/4">
        {renderCatalog()}
        <ProductList
          categories={categories}
          subCategories={subCategories}
          showProductDetailModal={showProductDetailModal}
        />
      </div>
    )
  }

  const renderEstablishmentInfo = () => {
    if (isLoading) {
      return null
    }
    return <EstablishmentInfo />
  }

  const renderCart = () => {
    if (isLoading) {
      return null
    }

    return <Cart />
  }

  return (
    <div>
      <SEO
        title={
          establishmentInfo.establishment_name
            ? establishmentInfo.establishment_name
            : "Estabelecimentos"
        }
      />
      <div>{renderEstablishmentInfo()}</div>
      <div className="shadow-lg sticky top-0 bg-white z-10">
        {renderCategories()}
      </div>
      <div className="md:flex mx-auto pb-[150px] md:pb-0">
        {renderContent()}
        {renderCart()}
      </div>
      <ProductInfoModal ref={productModalRef} />
    </div>
  )
}

export default Establishment
