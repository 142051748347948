import { useEffect } from "react"

export default function useViewPort(ref, handleViewPort) {
  function getWindowDimensions() {
    const {
      innerWidth: width,
      innerHeight: height,
      pageYOffset,
      pageXOffset,
    } = window
    return {
      width,
      height,
      pageYOffset,
      pageXOffset,
    }
  }

  function checkIfIsViewPort() {
    if (!ref.current) {
      return
    }

    const { height } = getWindowDimensions()

    const { top } = ref.current.getBoundingClientRect()

    const VIEW_PORT = height / 2

    if (top > VIEW_PORT || top < 80) {
      return
    }

    handleViewPort?.()
  }

  useEffect(() => {
    function handleResize() {
      checkIfIsViewPort()
    }

    window.addEventListener("resize", handleResize)
    window.addEventListener("scroll", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("scroll", handleResize)
    }
  }, [])
}
