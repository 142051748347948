import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"
import { formatBR2 } from "../../../helpers/Functions"
import RadioBox from "../RadioBox"
import CheckBox from "../CheckBox"

const ProductAdditionalOptionsList = ({ optionals, type, onChange }) => {
  const [, setHasUpdate] = useState(0)

  const renderSingleChoice = () =>
    optionals.map((option, index) => (
      <button
        type="button"
        key={Math.random().toString(36).substring(2)}
        className="flex flex-row pb-4"
        onClick={() => {
          onChange(index)
          setHasUpdate(Math.random().toString(36).substring(2))
        }}
      >
        <RadioBox value={option.quantity === 1} />
        <div className="flex flex-col md:flex-row items-start">
          <p className="ml-3 min-w-[150px] text-left pt-px">{option.name}</p>
          {option.price > 0 ? (
            <p className="text-primary ml-3">{`(+ R$ ${formatBR2(
              option.price
            )})`}</p>
          ) : null}
        </div>
      </button>
    ))

  const renderSingleChoicePrice = () =>
    optionals.map((option, index) => (
      <button
        type="button"
        key={Math.random().toString(36).substring(2)}
        className="flex flex-row pb-4"
        onClick={() => {
          onChange(index)
          setHasUpdate(Math.random().toString(36).substring(2))
        }}
      >
        <RadioBox value={option.quantity === 1} />
        <div className="flex flex-col md:flex-row items-start">
          <p className="ml-3 min-w-[150px] text-left pt-px">{option.name}</p>
          {option.price > 0 ? (
            <p className="text-primary ml-3">{`(R$ ${formatBR2(
              option.price
            )})`}</p>
          ) : null}
        </div>
      </button>
    ))

  const renderMultipleChoice = () =>
    optionals.map((option, index) => (
      <button
        type="button"
        key={Math.random().toString(36).substring(2)}
        className="flex flex-row pb-4"
        onClick={() => {
          onChange(index)
          setHasUpdate(Math.random().toString(36).substring(2))
        }}
      >
        <CheckBox value={option.quantity === 1} />
        <div className="flex flex-col md:flex-row items-start">
          <p className="ml-3 min-w-[150px] text-left pt-px">{option.name}</p>
          {option.price > 0 ? (
            <p className="text-primary ml-3">{`(+ R$ ${formatBR2(
              option.price
            )})`}</p>
          ) : null}
        </div>
      </button>
    ))

  const renderMultipleChoiceQuantity = () =>
    optionals.map((option, index) => (
      <div
        className="flex items-center mt-3"
        key={Math.random().toString(36).substring(2)}
      >
        <div className="flex flex-col md:flex-row items-start w-full">
          <p className="flex-1 text-left pt-px">{option.name}</p>
          {option.price > 0 ? (
            <p className="text-primary md:mx-3">{`(+ R$ ${formatBR2(
              option.price
            )})`}</p>
          ) : null}
        </div>
        <div className="flex flex-row justify-center items-center rounded-full bg-light-grey">
          <button
            type="button"
            onClick={() => {
              onChange(index, "decrease")
              setHasUpdate(Math.random().toString(36).substring(2))
            }}
            className="rounded-full w-6 h-6 m-2 text-center pt-0.5 text-dark-grey transition-colors hover:bg-primary hover:text-white"
          >
            <FontAwesomeIcon icon={faMinus} />
          </button>
          <p className="text-primary text-xl px-1 py-2 select-none">
            {option.quantity}
          </p>
          <button
            type="button"
            onClick={() => {
              onChange(index, "increase")
              setHasUpdate(Math.random().toString(36).substring(2))
            }}
            className="rounded-full w-6 h-6 m-2 text-center pt-0.5 text-dark-grey transition-colors hover:bg-primary hover:text-white"
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
    ))
  const renderOptionals = () => {
    if (type === 1) {
      return renderSingleChoice()
    }

    if (type === 2) {
      return renderMultipleChoice()
    }

    if (type === 3) {
      return renderMultipleChoiceQuantity()
    }

    if (type === 4) {
      return renderSingleChoicePrice()
    }

    return null
  }

  return <div>{renderOptionals()}</div>
}

export default ProductAdditionalOptionsList
