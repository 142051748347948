exports.components = {
  "component---src-custom-pages-checkout-js": () => import("./../../../src/custom-pages/checkout.js" /* webpackChunkName: "component---src-custom-pages-checkout-js" */),
  "component---src-custom-pages-establishment-slug-js": () => import("./../../../src/custom-pages/establishmentSlug.js" /* webpackChunkName: "component---src-custom-pages-establishment-slug-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parceiros-js": () => import("./../../../src/pages/parceiros.js" /* webpackChunkName: "component---src-pages-parceiros-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */)
}

