import { faQuestion } from "@fortawesome/free-solid-svg-icons"
import { SET_MODAL_VISIBLE, SET_MODAL_PROPS } from "../constants"

const initialState = {
  title: "Atenção",
  message: "Tem certeza disso?",
  cancelButtonText: "Cancelar",
  confirmButtonText: "Confirmar",
  confirmAction: null,
  cancelAction: null,
  showCancelButton: true,
  showConfirmButton: true,
  isVisible: false,
  showIcon: true,
  icon: faQuestion,
  canCloseModal: true,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_VISIBLE: {
      const { isVisible } = action
      return { ...state, isVisible, canCloseModal: true }
    }
    case SET_MODAL_PROPS: {
      const { modalProps } = action
      const newState = { ...initialState, ...modalProps }
      return newState
    }
    default:
      return state
  }
}
