import React from "react"
import ProductListCategory from "./components/ProductListCategory"

const ProductList = ({ categories, subCategories, showProductDetailModal }) =>
  React.Children.toArray(
    categories.map(cate => (
      <ProductListCategory
        cate={cate}
        subCategories={subCategories}
        showProductDetailModal={showProductDetailModal}
      />
    ))
  )

export default ProductList
