import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { navigate } from "gatsby"
import React, { useRef } from "react"
import { useSelector } from "react-redux"
import HeaderNavigation from "../../Layout/HeaderNavigation"
import Modal from "../../Layout/Modal"
import CartReview from "../CartReview"

const Cart = () => {
  const modalRef = useRef()
  const isOpened = useSelector(state => state.order.establishment.is_opened)
  const orderProducts = useSelector(state => state.order.products)

  const renderButtonText = () => {
    if (!isOpened) {
      return "Estabelecimento Fechado"
    }
    return orderProducts.length === 0
      ? "Nenhum Item Adicionado"
      : "Revisar Itens e Pedir"
  }

  return (
    <>
      <div className="hidden md:block flex-initial w-1/4 bg-light-grey">
        <div className="h-full relative">
          <div className="p-8 sticky top-8">
            <CartReview />
          </div>
        </div>
        {isOpened && (
          <div className="from-dark-grey/40 via-dark-grey/40 bg-gradient-to-t px-8 py-2 sticky bottom-0">
            <button
              type="button"
              className="lg:text-xl w-full shadow-xl hover:bg-white hover:text-primary border border-primary rounded-full text-white bg-primary px-4 py-3 transition duration-300 disabled:bg-primary-light disabled:border-primary-light disabled:hover:text-white"
              onClick={() => {
                navigate(`/fechar-pedido`)
              }}
              disabled={orderProducts.length === 0}
            >
              FINALIZAR PEDIDO
              <FontAwesomeIcon icon={faAngleRight} className="ml-2 mb-0.5" />
            </button>
          </div>
        )}
      </div>
      <div className="block md:hidden">
        <div className="from-dark-grey/40 via-dark-grey/40 bg-gradient-to-t px-8 py-2 w-full fixed bottom-0">
          <button
            type="button"
            className="btn-primary w-full uppercase relative justify-center"
            onClick={() => {
              modalRef.current.showModal()
            }}
            disabled={orderProducts.length === 0}
          >
            {renderButtonText()}
            {isOpened && (
              <FontAwesomeIcon
                icon={faAngleRight}
                className="right-5 absolute"
              />
            )}
          </button>
        </div>
      </div>
      <Modal ref={modalRef} padding={0} showCloseButton={false}>
        <HeaderNavigation
          goBack={() => {
            modalRef.current.closeModal()
          }}
          title="Pedido Atual"
          marginBottom={0}
        />
        <div className="h-[95vh] flex flex-col">
          <div className="bg-white flex flex-1 overflow-y-auto p-8 mb-[20px]">
            <CartReview />
          </div>
          <div className="from-dark-grey/40 via-dark-grey/40 bg-gradient-to-t px-8 w-full">
            <button
              type="button"
              className="text-xl w-full shadow-xl hover:bg-white hover:text-primary border border-primary rounded-full text-white bg-primary px-4 py-3 transition duration-300 disabled:border-primary-light disabled:hover:text-white"
              onClick={() => {
                navigate(`/fechar-pedido`)
              }}
              disabled={orderProducts.length === 0}
            >
              FINALIZAR PEDIDO
              <FontAwesomeIcon icon={faAngleRight} className="ml-2 mb-0.5" />
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Cart
