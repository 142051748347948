/* eslint-disable global-require */
// leave off @2x/@3x

import logoPrimary from "../images/logo-primary.svg"
import logo from "../images/logo.svg"
import illustrationLocation from "../images/illustrations/location.svg"
import iconClose from "../images/icons/close.svg"
import iconLoading from "../images/icons/loading.svg"

const images = {
  logo,
  logoPrimary,
  illustrationLocation,
  iconClose,
  iconLoading,
}

export default images
