/* eslint-disable jsx-a11y/label-has-associated-control */
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./css/header.scss"
import Logo from "../../images/logo.svg"

const Header = ({ children, textHeader }) => (
  <header className={`shadow-2xl ${textHeader ? "text-header" : ""}`}>
    <div className="container max-w-6xl mx-auto px-4">
      <div className="main-header flex items-center justify-between">
        <div className="flex flex-1 justify-center items-center">
          <Link to="/">
            <div className="logo">
              <img src={Logo} alt="QueroPedir" title="QueroPedir" />
            </div>
          </Link>
        </div>
        <div className="flex md:flex-1 justify-end">
          <div id="menu" className="flex">
            <input id="toggle-menu" type="checkbox" />
            <label htmlFor="toggle-menu" className="hamburger">
              <div className="top-bun" />
              <div className="meat" />
              <div className="bottom-bun" />
            </label>
            <div className="nav">
              <nav>
                <Link to="/">Início</Link>
                <Link to="/parceiros">Cadastre seu Estabelecimento</Link>
                <Link to="/contato">Contato</Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row">{children}</div>
    </div>
  </header>
)

Header.propTypes = {
  textHeader: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Header.defaultProps = {
  textHeader: true,
}

export default Header
