import React, { useEffect, useRef, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { navigate } from "gatsby"
import {
  faExclamationCircle,
  faQuestionCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons"
import { DateTime } from "luxon"

import SEO from "../components/Layout/Seo"
import EstablishmentInfo from "../components/Elements/EstablishmentInfo"
import OrderReview from "../components/Elements/OrderReview"
import { updateUser } from "../store/actions/user"
import {
  isCellphone,
  mascaraTelefone,
  mascaraCelular,
  mountProductDescription,
  formatBRFromString,
} from "../helpers/Functions"
import Select from "../components/Elements/Select"
import AddressSelection from "../components/Elements/AddressSelection"
import Input from "../components/Elements/Input"
import validation from "../helpers/validation_wrapper"
import CheckBox from "../components/Elements/CheckBox"
import CheckoutSuccess from "../components/Elements/CheckoutSuccess"
import HeaderNavigation from "../components/Layout/HeaderNavigation"
import MainEstablishment from "../components/Layout/MainEstablishment"
import { setModalProps, setModalVisible } from "../store/actions/modal"
import { saveOrder } from "../helpers/apiCalls"
import { cleanCart } from "../store/actions/order"
import AddressButton from "../components/Elements/AddressButton"

const DELIVERY = "Entrega"
const TAKEOUT = "Retirada"

const MONEY = "money"
const CREDITCARD = "creditcard"
const DEBITCARD = "debitcard"

const Checkout = () => {
  const currentUser = useSelector(state => state.user.user)
  const establishmentInfo = useSelector(state => state.order.establishment)
  const products = useSelector(state => state.order.products)
  const ipInfo = useSelector(state => state.order.ipInfo)
  const deliveryInfo = useSelector(state => state.order.deliveryInfo)

  const dispatch = useDispatch()
  const [, setHasUpdate] = useState(0)
  const [orderTypes, setOrderTypes] = useState([])
  const [orderTypeSelected, setOrderTypeSelected] = useState(null)
  const [showError, setShowError] = useState(false)
  const [orderPaymentsModes, setOrderPaymentsModes] = useState([])
  const [orderPaymentModeSelected, setOrderPaymentModeSelected] = useState(null)
  const successModalRef = useRef()
  const addressSelectionModalRef = useRef()
  const [haveChange, setHaveChange] = useState(null)
  const [orderSaved, setOrderSaved] = useState(null)

  useEffect(() => {
    // LOAD ORDER TYPES FROM ESTABLISHMENT
    const ordersTypes = []
    if (establishmentInfo?.is_delivery === 1) {
      ordersTypes.push("Entrega")
    }
    if (establishmentInfo?.is_takeaway === 1) {
      ordersTypes.push("Retirada")
    }
    setOrderTypes(ordersTypes)
    setOrderTypeSelected(ordersTypes?.[0])

    const paymentsMode = [
      {
        id: 1,
        name: "Na Entrega",
        code: "delivery",
        types: [
          {
            id: "money",
            name: "Dinheiro",
            image:
              "https://queropedir-static.s3-sa-east-1.amazonaws.com/payments_icons/money.png",
            selected: false,
          },
          {
            id: "creditcard",
            name: "Cartão de Crédito",
            image: null,
            selected: false,
            availables: [
              {
                name: "Mastercard",
                image:
                  "https://queropedir-static.s3-sa-east-1.amazonaws.com/payments_icons/master.png",
              },
              {
                name: "Visa",
                image:
                  "https://queropedir-static.s3-sa-east-1.amazonaws.com/payments_icons/visa.png",
              },
              {
                name: "American Express",
                image:
                  "https://queropedir-static.s3-sa-east-1.amazonaws.com/payments_icons/amex.png",
              },
              {
                name: "Hipercard",
                image:
                  "https://queropedir-static.s3-sa-east-1.amazonaws.com/payments_icons/hipercard.png",
              },
              {
                name: "Elo",
                image:
                  "https://queropedir-static.s3-sa-east-1.amazonaws.com/payments_icons/elo.png",
              },
              {
                name: "Diners",
                image:
                  "https://queropedir-static.s3-sa-east-1.amazonaws.com/payments_icons/diners.png",
              },
            ],
          },
          {
            id: "debitcard",
            name: "Cartão de Débito",
            image: null,
            selected: false,
            availables: [
              {
                name: "Mastercard",
                image:
                  "https://queropedir-static.s3-sa-east-1.amazonaws.com/payments_icons/master.png",
              },
              {
                name: "Visa",
                image:
                  "https://queropedir-static.s3-sa-east-1.amazonaws.com/payments_icons/visa.png",
              },
              {
                name: "American Express",
                image:
                  "https://queropedir-static.s3-sa-east-1.amazonaws.com/payments_icons/amex.png",
              },
              {
                name: "Hipercard",
                image:
                  "https://queropedir-static.s3-sa-east-1.amazonaws.com/payments_icons/hipercard.png",
              },
              {
                name: "Elo",
                image:
                  "https://queropedir-static.s3-sa-east-1.amazonaws.com/payments_icons/elo.png",
              },
              {
                name: "Diners",
                image:
                  "https://queropedir-static.s3-sa-east-1.amazonaws.com/payments_icons/diners.png",
              },
            ],
          },
        ],
      },
    ]

    setOrderPaymentsModes(paymentsMode)
    setOrderPaymentModeSelected(paymentsMode?.[0]?.id)
  }, [])

  const onChange = event => {
    const user = currentUser || {}
    if (event.target.id === "name") {
      user.name = event.target.value
    }

    if (event.target.id === "phoneNumber") {
      if (isCellphone(event.target.value)) {
        user.phoneNumber = mascaraCelular(event.target.value)
      } else {
        user.phoneNumber = mascaraTelefone(event.target.value)
      }
    }

    dispatch(updateUser(user))
    setHasUpdate(Math.random().toString(36).substring(2))
  }

  const renderAddress = () => {
    if (orderTypeSelected === TAKEOUT) {
      return (
        <p className="text-primary text-sm">
          {`${establishmentInfo?.street_prefix} ${
            establishmentInfo?.address
          }, ${establishmentInfo?.addres_number}${
            establishmentInfo?.address_complement?.length > 0
              ? ` - ${establishmentInfo?.address_complement}`
              : ""
          } - ${establishmentInfo?.address_neighborhood}`}
        </p>
      )
    }

    return (
      <AddressButton
        onClick={() => addressSelectionModalRef.current.showModal()}
      />
    )
  }

  const selectPaymentType = (paymentModeId, indexType) => {
    const auxOrderPaymentsModes = orderPaymentsModes

    const indexPaymentMode = orderPaymentsModes.findIndex(
      item => item.id === paymentModeId
    )

    auxOrderPaymentsModes[indexPaymentMode].types[indexType].selected =
      !auxOrderPaymentsModes[indexPaymentMode].types[indexType].selected

    setOrderPaymentsModes(auxOrderPaymentsModes)
    setHasUpdate(Math.random().toString(36).substring(2))
  }

  const renderHaveChange = type => {
    if (type.id !== MONEY || !type.selected) {
      return null
    }

    return (
      <div className="px-2 py-4">
        <p className="text-base">Preciso de troco para:</p>
        <Input
          className="w-full"
          id="haveChange"
          label="Troco"
          value={haveChange || ""}
          onChange={e => {
            setHaveChange(formatBRFromString(e.target.value))
          }}
        />
      </div>
    )
  }
  const renderPaymentMode = () => {
    if (orderTypeSelected === TAKEOUT) {
      return null
    }

    return (
      <div className="card-centered items-start">
        <p className="text-xl uppercase mb-3">Forma de Pagamento:</p>
        {/* <Select
          options={orderPaymentsModes.map(paymentMode => ({
            label: paymentMode.name,
            value: paymentMode.id,
          }))}
          optionPress={value => {
            setOrderPaymentModeSelected(value)
          }}
          optionSelected={orderPaymentModeSelected}
          type="advanced"
        /> */}
        <div className="mt-5 ml-2">
          {React.Children.toArray(
            orderPaymentsModes[0]?.types.map((type, index) => (
              <>
                <button
                  type="button"
                  className="flex mb-2 items-center"
                  key={Math.random().toString(36).substring(2)}
                  onClick={() => {
                    selectPaymentType(orderPaymentModeSelected, index)
                  }}
                >
                  <CheckBox value={type.selected} />
                  <p className="ml-3 text-xl">{type.name}</p>
                </button>
                {renderHaveChange(type)}
              </>
            ))
          )}
        </div>
      </div>
    )
  }

  const mountDeliveryOrderObject = async () => {
    const { currentAddress } = currentUser

    try {
      const order = {
        id_establishment: establishmentInfo?.id,
        id_client: 0,
        name: currentUser?.name,
        phone: currentUser?.phoneNumber,
        celphone: currentUser?.phoneNumber,
        email: "",
        cpf: "",
        rg: "",
        id_address: 0,
        street_prefix_delivery: currentAddress?.streetPrefix || "",
        address_delivery: currentAddress?.address || "",
        address_complement_delivery: currentAddress?.addressComplement || "",
        address_number_delivery: currentAddress?.addressNumber || "",
        address_neighborhood_delivery:
          currentAddress?.addressNeighborhood || "",
        address_city_delivery: currentAddress?.addressCity || "",
        address_state_delivery: currentAddress?.addressState || "",
        address_zipcode_delivery: currentAddress?.addressZipCode || "",
        subtotal: 0.0,
        delivery_tax: 0.0,
        discount_cupom: "",
        discount: 0,
        total: 0.0,
        flag_takeout: orderTypeSelected === DELIVERY ? 0 : 1,
        flag_money: 0,
        have_change: null,
        flag_card: 0,
        cpfcnpj_invoice: "",
        status: "pending",
        date_time_creation: DateTime.now().toFormat("yyyy-LL-dd TT"),
        ip_address: JSON.stringify(ipInfo),
        cel_model: "",
        manufacturer: "",
        os_version_cel: "",
        push_token: "",
        payment_mode: 1,
        card_id: "",
        card_cpf_cnpj: "",
        items: [],
        web_version: true,
        version: "4.0",
      }

      if (orderTypeSelected === DELIVERY) {
        order.delivery_tax = deliveryInfo.delivery_tax
          ? deliveryInfo.delivery_tax
          : 0
      }
      // CHECK IF PAYMENT TYPE IS SELECTED
      orderPaymentsModes[0]?.types?.forEach(type => {
        if (!type.selected) {
          return
        }

        switch (type.id) {
          case MONEY: {
            order.flag_money = 1
            order.have_change = haveChange
            break
          }
          case CREDITCARD: {
            order.flag_card = 1
            break
          }
          case DEBITCARD: {
            order.flag_card = 1
            order.flag_debit_card = 1
            break
          }
          default:
            break
        }
      })

      let totalProducts = 0.0
      products.forEach(prod => {
        console.log(prod)
        const productOrder = {
          id_product: prod.id,
          date_time_creation: DateTime.now().toFormat("yyyy-LL-dd TT"),
          sku: prod.sku,
          name: prod.app_name,
          quantity: prod.quantity,
          obj_additionals: {
            ingredients: prod.ingredients,
            additionals: prod.additionals,
          },
          obs_additionals: "",
          unit_price: prod.price,
          additionals_price: prod.additonalsPrice,
          total: prod.total,
          id_menu: 0,
          menu_name: "",
          id_category: 0,
          category_name: 0,
          id_subcategory: 0,
          subcategory_name: "",
          obs: mountProductDescription(prod.ingredients, prod.additionals),
          obj_configurable: null,
        }

        totalProducts += prod.total
        order.items.push(productOrder)
      })

      order.subtotal = totalProducts
      // MUST ADD THE DELIVERY PRICE
      order.total = totalProducts + order.delivery_tax

      console.log(order)
      return order
    } catch (e) {
      return null
    }
  }

  const submitOrder = async () => {
    const order = await mountDeliveryOrderObject()

    dispatch(
      setModalProps({
        title: "Enviando Pedido",
        message: "Por favor aguarde",
        showCancelButton: false,
        showConfirmButton: false,
        icon: faSpinner,
        isVisible: true,
        canCloseModal: false,
      })
    )
    await saveOrder(order)
      .then(response => {
        dispatch(setModalVisible(false))
        if (response.errorCode) {
          setTimeout(() => {
            dispatch(
              setModalProps({
                title: "Atenção",
                message:
                  response.message ||
                  "Ocorreu uma falha ao enviar o Pedido. Por favor, tente novamente",
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: "Ok",
                icon: faExclamationCircle,
                isVisible: true,
                canCloseModal: false,
              })
            )
          }, 50)
          return
        }

        // CREATE SUCCESS

        dispatch(cleanCart())
        setOrderSaved(response)
        successModalRef.current.showModal()
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(
            setModalProps({
              title: "Atenção",
              message:
                "Ocorreu uma falha ao enviar o Pedido. Por favor, tente novamente",
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: "Ok",
              icon: faExclamationCircle,
              isVisible: true,
              canCloseModal: false,
            })
          )
        }, 50)
      })
  }

  const onConfirm = () => {
    // CHECK IF REQUIRED FIELDS WERE FILLED
    if (
      currentUser.name?.length === 0 ||
      currentUser.phoneNumber?.length === 0
    ) {
      setShowError(true)
      return
    }

    // CHECK IF TELEPHONE IS VALID

    const isNumberValid = validation(
      isCellphone(currentUser.phoneNumber) ? "celular" : "fone",
      currentUser?.phoneNumber
    )

    if (isNumberValid) {
      setShowError(true)
      return
    }

    const { currentAddress } = currentUser

    // CHECK IF HAVE ADDRESS FILLED WHEN DELIVERY
    if (!currentAddress && orderTypeSelected === DELIVERY) {
      dispatch(
        setModalProps({
          title: "Atenção",
          message: "Por favor, informe o endereço de entrega",
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: "Ok",
          icon: faExclamationCircle,
          isVisible: true,
        })
      )
      return
    }

    // CHECK IF PAYMENT TYPE IS SELECTED
    const hasSelectedAnyPaymentOption = orderPaymentsModes[0]?.types?.filter(
      type => type.selected
    )

    if (
      hasSelectedAnyPaymentOption.length === 0 &&
      orderTypeSelected === DELIVERY
    ) {
      dispatch(
        setModalProps({
          title: "Atenção",
          message: "Selecione uma forma de pagamento",
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: "Ok",
          icon: faExclamationCircle,
          isVisible: true,
        })
      )
      return
    }

    dispatch(
      setModalProps({
        title: "Confirmar Pedido",
        message: "Confirma o envio do pedido?",
        cancelButtonText: "Voltar",
        confirmButtonText: "Confirmar",
        confirmAction: () => {
          setTimeout(() => {
            submitOrder()
          }, 100)
        },
        icon: faQuestionCircle,
        isVisible: true,
      })
    )
  }

  return (
    <MainEstablishment>
      <HeaderNavigation
        goBack={() => {
          navigate(-1)
        }}
        title="Finalizar Pedido"
        marginBottom={0}
      />
      <div className="w-screen h-screen bg-light-grey flex px-4 mb-8">
        <div className="flex flex-1 flex-col">
          <SEO title="Fechar Pedido" />
          <div className="card-centered">
            <EstablishmentInfo hideAddressDetails />
          </div>
          <div className="card-centered items-start">
            <p className="text-xl uppercase mb-3">O Pedido será para:</p>
            <Select
              options={orderTypes}
              optionPress={selection => {
                setOrderTypeSelected(selection)
              }}
              optionSelected={orderTypeSelected}
            />
            <p className="mt-5">
              {`Endereço de `}
              <span className="font-bold">
                {orderTypeSelected === DELIVERY ? "ENTREGA" : "RETIRADA"}
              </span>
              :
            </p>
            {renderAddress()}
          </div>
          {renderPaymentMode()}
          <div className="card-centered items-start">
            <p className="text-xl uppercase ">Dados de Contato:</p>
            <div className="w-full md:w-fit">
              <Input
                className="w-full"
                id="name"
                label="Nome"
                value={currentUser.name ? currentUser.name : ""}
                onChange={onChange}
                validationMessage={
                  currentUser.name?.length > 0 || showError
                    ? validation("nome", currentUser?.name)
                    : null
                }
              />
            </div>
            <div className="w-full md:w-fit">
              <Input
                className="w-full"
                id="phoneNumber"
                label="Telefone"
                value={currentUser.phoneNumber ? currentUser.phoneNumber : ""}
                onChange={onChange}
                length={isCellphone(currentUser.phoneNumber) ? 15 : 14}
                validationMessage={
                  currentUser.phoneNumber?.length > 0 || showError
                    ? validation(
                        isCellphone(currentUser.phoneNumber)
                          ? "celular"
                          : "fone",
                        currentUser?.phoneNumber
                      )
                    : null
                }
              />
            </div>
          </div>
          <div className="card-centered items-start">
            <OrderReview showDeliveryValue={orderTypeSelected === DELIVERY} />
          </div>
          <div className="container mx-auto items-start mb-5">
            <div className="w-full">
              <button
                type="button"
                className="btn-primary w-full"
                onClick={() => onConfirm()}
              >
                <p className="text-xl lg:text-2xl uppercase w-full">
                  Confirmar Pedido
                </p>
              </button>
            </div>
          </div>

          <AddressSelection
            ref={addressSelectionModalRef}
            closeModal={() => {
              addressSelectionModalRef.current.closeModal()
              setHasUpdate(Math.random().toString(36).substring(2))
            }}
            fromCheckout
          />
        </div>
      </div>
      <CheckoutSuccess ref={successModalRef} orderSaved={orderSaved} />
    </MainEstablishment>
  )
}

export default Checkout
