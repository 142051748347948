import React, { useCallback, useRef } from "react"
import { useDispatch } from "react-redux"
import useViewPort from "../../../../hooks/useViewPort"
import { setCurrentCategory } from "../../../../store/actions/category"
import ProductListSubcategory from "./ProductListSubcategory"

const ProductListCategory = ({
  cate,
  subCategories,
  showProductDetailModal,
}) => {
  const ref = useRef(null)
  const dispatch = useDispatch()

  const updateCategory = useCallback(() => {
    dispatch(setCurrentCategory(cate.id))
  }, [])

  useViewPort(ref, updateCategory)

  const subCategoriesFromCategory = subCategories.filter(
    subCate => subCate.category === cate.id
  )

  return (
    <div
      ref={ref}
      className="scroll-mt-20 mt-8 border-b border-b-light-grey pb-4 px-8"
      id={`container-cate-${cate.id}`}
      key={Math.random().toString(36).substring(2)}
    >
      <div>
        <p className="text-xl text-primary uppercase font-bold tracking-wider">
          {cate.name}
        </p>
        {React.Children.toArray(
          subCategoriesFromCategory.map((subCate, index) => (
            <ProductListSubcategory
              index={index}
              subCate={subCate}
              showProductDetailModal={showProductDetailModal}
            />
          ))
        )}
      </div>
    </div>
  )
}

export default ProductListCategory
