import { SET_CURRENT_CATEGORY } from "../constants"

const initialState = {
  currentCategoryId: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_CATEGORY: {
      const { categoryId } = action
      return { ...state, currentCategoryId: categoryId }
    }
    default:
      return state
  }
}
