import {
  ADD_PRODUCT,
  CLEAN_CART,
  REMOVE_PRODUCT,
  SET_DELIVERY_INFO,
  SET_ESTABLISHNEMT_ORDER,
  SET_IP_INFO,
} from "../constants"

const initialState = {
  products: [],
  establishment: {},
  ipInfo: {},
  deliveryInfo: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT: {
      return { ...state, products: [...state.products, action.product] }
    }
    case REMOVE_PRODUCT: {
      const stateTemp = state.products.filter(
        item => item.cart_id !== state.products[action.productPosition].cart_id
      )

      return { ...state, products: stateTemp }
    }
    case SET_ESTABLISHNEMT_ORDER: {
      const { establishment } = action

      const ordersTypes = []
      if (establishment?.is_delivery === 1) {
        if (establishment?.delivery_status === 1) {
          ordersTypes.push("Entrega")
        }
      }
      if (establishment?.is_takeaway === 1) {
        if (establishment?.takeaway_status === 1) {
          ordersTypes.push("Retirada")
        }
      }

      establishment.is_opened = false
      if (ordersTypes.length > 0) {
        establishment.is_opened = true
      }
      return { ...state, establishment }
    }
    case SET_IP_INFO: {
      const { ipInfo } = action
      return { ...state, ipInfo }
    }
    case SET_DELIVERY_INFO: {
      const { deliveryInfo } = action
      return { ...state, deliveryInfo }
    }
    case CLEAN_CART: {
      return { ...state, products: [] }
    }
    default:
      return state
  }
}
