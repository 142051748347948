import React from "react"
import { useSelector } from "react-redux"
import { formatBR2 } from "../../../helpers/Functions"

const OrderReview = ({ showDeliveryValue = true }) => {
  const products = useSelector(state => state.order.products)
  const deliveryInfo = useSelector(state => state.order.deliveryInfo)

  const totalProducts = products.reduce(
    // eslint-disable-next-line no-return-assign
    (acc, current) => (acc += current.total),
    0
  )

  // eslint-disable-next-line no-nested-ternary
  const deliveryTax = showDeliveryValue
    ? deliveryInfo?.delivery_tax
      ? deliveryInfo.delivery_tax
      : 0
    : 0

  return (
    <div className="w-full md:w-fit">
      <p className="text-xl uppercase">Resumo do Pedido:</p>
      <div className="mt-4">
        {React.Children.toArray(
          products.map(product => (
            <div className="flex flex-row">
              <p className="font-bold text-primary mr-3">{`${product.quantity}x`}</p>
              <p className="font-bold break-normal line-clamp-2 break-words flex-1 mr-16">
                {product.app_name}
              </p>
              <p>{`R$ ${formatBR2(product.total)}`}</p>
            </div>
          ))
        )}
        <div className="border-b my-2 border-b-dark-grey/20" />
        <div className="flex flex-row">
          <p className="break-normal line-clamp-2 break-words flex-1 mr-16">
            Total Produtos
          </p>
          <p>{`R$ ${formatBR2(totalProducts)}`}</p>
        </div>
        {showDeliveryValue && (
          <div className="flex flex-row">
            <p className="break-normal line-clamp-2 break-words flex-1 mr-16">
              Taxa de Entrega
            </p>
            <p>{`R$ ${formatBR2(deliveryTax)}`}</p>
          </div>
        )}

        <div className="flex flex-row">
          <p className="font-bold break-normal line-clamp-2 break-words flex-1 mr-16">
            Total do Pedido
          </p>
          <p className="font-bold">{`R$ ${formatBR2(
            totalProducts + deliveryTax
          )}`}</p>
        </div>
      </div>
    </div>
  )
}

export default OrderReview
