import React from "react"

const RadioBox = ({ value }) => (
  <div className="flex flex-row">
    <div className="border-2 border-primary w-6 h-6 p-0.5 flex justify-items-center items-center rounded-full relative">
      <div
        className={`rounded-full ${value ? "bg-primary" : ""} p-[7px] ml-[1px]`}
      />
    </div>
  </div>
)

export default RadioBox
