import { faExclamationCircle, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { formatBR2, mountProductDescription } from "../../../helpers/Functions"
import { setModalProps } from "../../../store/actions/modal"
import { removeProduct } from "../../../store/actions/order"

const CartReview = () => {
  const [, setHasUpdate] = useState(0)
  const dispatch = useDispatch()
  const orderProducts = useSelector(state => state.order.products)
  const isOpened = useSelector(state => state.order.establishment.is_opened)

  const showDeleteConfirmationModal = index => {
    dispatch(
      setModalProps({
        title: "Remover Item",
        message: "Confirma a remoção do item?",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Remover",
        confirmAction: () => {
          dispatch(removeProduct(index))
          setHasUpdate(Math.random().toString(36).substring(2))
        },
        icon: faExclamationCircle,
        isVisible: true,
      })
    )
  }

  const renderCartDescription = () => {
    if (!isOpened) {
      return "Aguarde que o estabelecimento esteja aberto para realizar pedidos."
    }

    if (orderProducts.length === 0) {
      return "Você ainda não adicionou nenhum item no seu pedido."
    }

    return "Revise seus itens e pressione finalizar pedido quando estiver pronto."
  }

  return (
    <div>
      <p className="lg:text-xl text-primary uppercase font-bold tracking-wider break-words">
        {isOpened ? "Pedido Atual" : "Estabelecimento Fechado"}
      </p>
      <p className="pt-2">{renderCartDescription()}</p>
      <div className="mb-12">
        {React.Children.toArray(
          orderProducts.map((product, index) => (
            <div className="card bg-white display-flex flex-row mx-0">
              <button
                type="button"
                className="animation transition-colors hover:text-primary py-4"
                onClick={() => {
                  showDeleteConfirmationModal(index)
                  setHasUpdate(Math.random().toString(36).substring(2))
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
              <div className="flex-1 px-4">
                <p className="font-bold">{`${product.quantity}x ${product.app_name}`}</p>
                <p className="text-dark-grey">
                  {mountProductDescription(
                    product.ingredients,
                    product.additionals
                  )}
                </p>
                <p className="text-primary">{`R$${formatBR2(
                  product.total
                )}`}</p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default CartReview
