export const UPDATE_USER = "UPDATE_USER"
export const ADD_PRODUCT = "ADD_PRODUCT"
export const REMOVE_PRODUCT = "REMOVE_PRODUCT"
export const SET_ESTABLISHNEMT_ORDER = "SET_ESTABLISHNEMT_ORDER"
export const SET_MODAL_VISIBLE = "SET_MODAL_VISIBLE"
export const SET_MODAL_PROPS = "SET_MODAL_PROPS"
export const SET_IP_INFO = "SET_IP_INFO"
export const SET_DELIVERY_INFO = "SET_DELIVERY_INFO"
export const CLEAN_CART = "CLEAN_CART"
export const SET_CURRENT_CATEGORY = "SET_CURRENT_CATEGORY"
