import { Link } from "gatsby"
import React from "react"
import "./css/footer.scss"
import Logo from "../../images/logo.svg"

const Footer = () => (
  <footer>
    <div className="max-w-6xl mx-auto flex justify-center">
      <div className="md:flex md:flex-1 main-footer my-4">
        <div className="flex flex-1 items-start">
          <img
            className="w-[200px]"
            src={Logo}
            alt="QueroPedir"
            title="QueroPedir"
          />
        </div>
        <div className="mt-5 md:mt-0 flex flex-1 flex-col justify-start">
          <p className="h4">Saiba Mais</p>
          <Link className="h5 text-base" to="/">
            Início
          </Link>
          <Link className="h5 text-base" to="/parceiros">
            Cadastre seu Estabelecimento
          </Link>
          <Link className="h5 text-base" to="/contato">
            Contato
          </Link>
        </div>
        <div className="mt-5 md:mt-0 flex flex-1 flex-col justify-start">
          <p className="h4">Encontre-nos</p>
          <a
            className="h5 text-base"
            href="https://www.facebook.com/queropedirapp"
            target="_BLANK"
            rel="noreferrer"
          >
            Facebook
          </a>
          <a
            className="h5 text-base"
            href="https://www.instagram.com/queropedirapp"
            target="_BLANK"
            rel="noreferrer"
          >
            Instagram
          </a>
        </div>
      </div>
    </div>
    <div className="copyright-footer">
      <p className="text-dark-grey text-center mx-2">
        © {new Date().getFullYear()}, <strong>QueroPedir</strong> é um
        aplicativo da empresa
        {` `}
        <a
          className="text-primary"
          href="https://www.systemcode.com.br"
          target="_BLANK"
          rel="noreferrer"
        >
          System Code
        </a>
        .
      </p>
    </div>
  </footer>
)

export default Footer
