import React, { memo } from "react"
import { useSelector } from "react-redux"

const CategoryList = ({ categories }) => {
  const { currentCategoryId } = useSelector(state => state.category)

  const scroll = id => {
    const section = document.querySelector(`#container-cate-${id}`)
    console.log(section.getBoundingClientRect())
    const y = section.getBoundingClientRect().top

    section.scrollIntoView({ top: y, behavior: "smooth" })
  }

  return (
    <ul className="lg:justify-center overflow-x-auto flex flex-row w-full">
      {React.Children.toArray(
        categories.map(cate => (
          <li className="flex-shrink-0 cursor-pointer">
            <button
              type="button"
              onClick={() => {
                scroll(cate.id)
              }}
            >
              <p
                className={`${
                  currentCategoryId === cate.id
                    ? "text-primary"
                    : "text-dark-grey border-b-white hover:border-b-light-grey"
                } border-b-[3px] py-2 px-2 hover:bg-light-grey`}
              >
                {cate.name}
              </p>
            </button>
          </li>
        ))
      )}
    </ul>
  )
}

export default memo(CategoryList)
