import React from "react"
import Layout from "../components/LayoutSite/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import Form from "../components/form/form"

const ContactPage = () => (
  <Layout>
    <SEO
      title="QueroPedir - Fale Conosco"
      description="Neste espaço você pode esclarecer suas dúvidas, dar sua opinião ou sugestão e falar com a gente sobre outros assuntos que desejar. "
      keywords={[`contato`, `fale conosco`]}
    />
    <Header siteTitle="QueroPedir">
      <h1 className="text-4xl text-white">Fale Conosco</h1>
    </Header>
    <div className="container mx-auto max-w-6xl become-partner p-5">
      <div className="row text-center">
        <h4 className="h4">
          Neste espaço você pode esclarecer suas dúvidas, dar sua opinião ou
          sugestão
          <br className="hidden md:block" />e falar com a gente sobre outros
          assuntos que desejar <strong>=)</strong>
        </h4>
      </div>
      <div className="flex flex-col">
        <Form formName="Contato">
          <input
            name="Nome"
            type="text"
            placeholder="Nome"
            className="form-control input-sc"
            required
          />
          <select
            name="Assunto"
            className="form-control input-sc bg-white"
            required
          >
            <option value="" disabled selected hidden>
              Assunto
            </option>
            <option value="Elogios">Elogios</option>
            <option value="Sugestões">Sugestões</option>
            <option value="Reclamações">Reclamações</option>
            <option value="Problemas com Pedido">
              Problemas com um Pedido
            </option>
            <option value="Problemas com o Aplicativo">
              Problemas no Aplicativo
            </option>
            <option value="Outros">Outros</option>
          </select>
          <input
            name="Email"
            type="email"
            placeholder="E-mail"
            className="form-control input-sc"
            required
          />
          <input
            name="Telefone"
            type="text"
            placeholder="Telefone"
            className="form-control input-sc"
            required
          />
          <textarea
            name="Mensagem"
            type="text"
            placeholder="Mensagem"
            className="form-control input-sc"
            rows="5"
            required
          />
        </Form>
      </div>
    </div>
  </Layout>
)

export default ContactPage
