/* eslint-disable camelcase */
import React from "react"
// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-unresolved
import { Router } from "@reach/router"
import Establishment from "../components/Elements/Establishment"
import MainEstablishment from "../components/Layout/MainEstablishment"

const App = () => (
  <div className="app">
    <Router>
      <ItemComp path="/catalogo/:slug" />
    </Router>
  </div>
)

const ItemComp = ({ navigate, slug }) => (
  <MainEstablishment>
    <Establishment navigate={navigate} establishment={slug} />
  </MainEstablishment>
)

export default App
