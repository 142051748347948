import React from "react"

import "./style.scss"
import Button from "../Button"

const Select = ({ options, optionPress, optionSelected, type = "simple" }) => {
  if (type === "simple") {
    return (
      <div className="flex flex-row w-full md:w-fit">
        {React.Children.toArray(
          options.map((option, index) => (
            <Button
              customStyle={`px-4 py-2 flex-1 md:flex-0 uppercase ${
                index !== options.length - 1 ? "mr-5" : ""
              } ${
                optionSelected === option
                  ? "primary text-white"
                  : "hover:bg-primary/10"
              }`}
              type={optionSelected === option ? "primary" : "secondary"}
              onClick={() => optionPress(option)}
            >
              <p className="text-center w-full text-lg">{option}</p>
            </Button>
          ))
        )}
      </div>
    )
  }

  return (
    <div className="flex flex-row w-full md:w-fit">
      {React.Children.toArray(
        options.map((option, index) => (
          <Button
            customStyle={`px-4 py-2 flex-1 md:flex-0 uppercase ${
              index !== options.length - 1 ? "mr-5" : ""
            } ${
              optionSelected === option.value
                ? "primary text-white"
                : "hover:bg-primary/10"
            }`}
            type={optionSelected === option.value ? "primary" : "secondary"}
            onClick={() => optionPress(option.value)}
          >
            <p className="text-center w-full text-lg">{option.label}</p>
          </Button>
        ))
      )}
    </div>
  )
}

export default Select
