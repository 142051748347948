import { Link } from "gatsby"
import React, { memo } from "react"
import ProductListItem from "../../ProductListItem"

const ProductListSubcategory = ({ index, subCate, showProductDetailModal }) => (
  <div>
    <p
      className={`uppercase text-primary tracking-wider ${
        index === 0 ? "pt-2" : "pt-8"
      }`}
    >
      {subCate.name}
    </p>
    <div className="grid sm:grid-cols-2 gap-2 2xl:grid-cols-3">
      {React.Children.toArray(
        subCate.products.map(product => (
          <Link to={`#${product.slug}`}>
            <ProductListItem
              product={product}
              showProductDetailModal={showProductDetailModal}
            />
          </Link>
        ))
      )}
    </div>
  </div>
)

export default memo(ProductListSubcategory)
