import React from "react"

import "./style.scss"

const Button = ({
  children,
  onClick = null,
  type = null,
  customStyle = null,
}) => {
  let className

  switch (type) {
    case "primary":
      className = "btn-primary"
      break
    case "secondary":
      className = "btn-secondary"
      break
    default:
      className = "btn-primary"
      break
  }

  return (
    <button
      type="button"
      className={`${className} ${customStyle || ""}`}
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      {children}
    </button>
  )
}

export default Button
