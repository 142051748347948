import React from "react"
import { Link } from "gatsby"

import "./css/index.scss"
import Layout from "../components/LayoutSite/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import ImageCellphone from "../images/home/header_cellphone.png"
import ImageAppStore from "../images/home/header_appstore.png"
import ImageGooglePlay from "../images/home/header_googleplay.png"
import IconMoney from "../images/icons/icon_money.svg"
import IconCreditCard from "../images/icons/icon_credit-card.png"
import IconIntegration from "../images/icons/icon_integration.png"
import IconInovate from "../images/icons/icon_inovate.png"
import IconTime from "../images/icons/icon_time.svg"
import IconCustomers from "../images/icons/icon_customers.svg"

const IndexPage = () => (
  <Layout>
    <SEO
      title="QueroPedir"
      description="Faça pedidos na mesa do estabelecimento ou para delivery em um só aplicativo."
      keywords={[
        `delivery`,
        `entregas`,
        `restaurantes`,
        `padarias`,
        `farmácias`,
        `bares`,
        `petshop`,
        `adegas`,
        `pedidos`,
        `aplicativo`,
        `pedidos`,
        `quero pedir`,
        `auto atendimento`,
        `auto-atendimento`,
      ]}
    />
    <Header textHeader={false}>
      <div className="hidden md:flex md:flex-1 md:justify-center">
        <img
          className="home-header-cellphone animated fadeInLeft slow"
          src={ImageCellphone}
          alt="QueroPedir"
          title="QueroPedir"
        />
      </div>
      <div className="flex flex-1 flex-col home-header-description animated fadeInRight">
        <h3 className="text-3xl px-3">
          O melhor aplicativo de <strong>marketplace</strong> para pedidos de{" "}
          <strong>entrega/retirada</strong> e <strong>auto-atendimento</strong>
        </h3>
        <div className="home-header-download">
          <a
            href="https://apps.apple.com/us/app/queropedir/id1465384758"
            target="_BLANK"
            rel="noreferrer"
          >
            <img src={ImageAppStore} alt="Apple Store" title="Apple Store" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.queropedir"
            target="_BLANK"
            rel="noreferrer"
          >
            <img src={ImageGooglePlay} alt="Google Play" title="Google Play" />
          </a>
        </div>
      </div>
    </Header>
    <div className="container mx-auto become-partner p-5">
      <div className="text-center">
        <h2 className="h2">
          Seja um <strong>Parceiro</strong>
        </h2>
      </div>
      <div className="text-center">
        <h4 className="h4">
          O aplicativo <strong>QueroPedir</strong> nasceu para facilitar
          <br className="hidden md:block" /> a maneira de receber pedidos tanto
          para delivery quanto para auto-atendimento.
        </h4>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4">
        <div className="container-features">
          <img
            src={IconMoney}
            alt="aumente suas vendas"
            title="aumente suas vendas"
            className="icon-features"
          />
          <h5 className="h5">
            <strong>aumente</strong> suas vendas
          </h5>
          <p className="text-description">
            tenha um canal a mais de vendas, facilite os pedidos e crie
            promoções
          </p>
        </div>
        <div className="container-features">
          <img
            src={IconTime}
            alt="agilize seu atendimento"
            title="agilize seu atendimento"
            className="icon-features"
          />
          <h5 className="h5">
            <strong>agilize</strong> seu atendimento
          </h5>
          <p className="text-description">
            não perca pedidos por falta
            <br className="block md:hidden" /> de atendimento mesmo nos dias
            mais cheios
          </p>
        </div>
        <div className="container-features">
          <img
            src={IconCreditCard}
            alt="informações de suas vendas"
            title="informações de suas vendas"
            className="icon-features"
          />
          <h5 className="h5">
            informações de suas <strong>vendas</strong>
          </h5>
          <p className="text-description">
            tenha um resumo de pedidos e pagamento com um painel detalhado
          </p>
        </div>
        <div className="container-features">
          <img
            src={IconCustomers}
            alt="gerencie de forma simples"
            title="gerencie de forma simples"
            className="icon-features"
          />
          <h5 className="h5">
            <strong>gerencie</strong> de forma simples
          </h5>
          <p className="text-description">
            controle seus pedidos e gerencie os produtos, catálogo e promoções
          </p>
        </div>
        <div className="container-features">
          <img
            src={IconInovate}
            alt="inove o recebimento de pedidos"
            title="inove o recebimento de pedidos"
            className="icon-features"
          />
          <h5 className="h5">
            <strong>inove</strong> o recebimento de pedidos
          </h5>
          <p className="text-description">
            receba pedidos no local com a leitura de um QR Code
          </p>
        </div>
        <div className="container-features">
          <img
            src={IconIntegration}
            alt="integre suas informações"
            title="integre suas informações"
            className="icon-features"
          />
          <h5 className="h5">
            <strong>integre</strong> suas informações
          </h5>
          <p className="text-description">
            mantemos unificado as informações com a integração do seu sistema
          </p>
        </div>
      </div>
      <div className="more-info flex justify-center">
        <Link to="/parceiros">
          <button className="button-sc-static" type="button">
            SAIBA MAIS
          </button>
        </Link>
      </div>
    </div>
  </Layout>
)

export default IndexPage
