import React, { useState } from "react"

const IngredientsList = ({ ingredients }) => {
  const [, setHasUpdate] = useState(0)

  const updateIngredient = index => {
    ingredients[index].selected = !ingredients[index].selected
    setHasUpdate(Math.random().toString(36).substring(2))
  }
  return (
    <div>
      <p className="text-lg text-primary">Ingredientes</p>
      <p className="text-dark-grey">
        Desmarque os ingredientes que deverão ser removidos do item
      </p>
      <div className="flex flex-row flex-wrap mt-1">
        {React.Children.toArray(
          ingredients.map((ingredient, index) => (
            <button
              type="button"
              className={`px-3 mt-2 py-1 border ${
                ingredient.selected
                  ? "border-primary hover:bg-primary/10"
                  : "border-dark-grey hover:bg-light-grey"
              } rounded mr-2`}
              onClick={() => {
                updateIngredient(index)
              }}
            >
              <p
                className={`${
                  ingredient.selected
                    ? "text-primary"
                    : "text-dark-grey line-through decoration-primary"
                }`}
              >
                {ingredient.name}
              </p>
            </button>
          ))
        )}
      </div>
    </div>
  )
}

export default IngredientsList
