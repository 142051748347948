import React, { forwardRef, useImperativeHandle, useRef, useState } from "react"
import Modal from "../../Layout/Modal"
import ProductInfo from "../ProductInfo"

const ProductInfoModal = forwardRef((props, ref) => {
  const [product, setProduct] = useState(null)
  const modalRef = useRef()

  const showModal = () => {
    modalRef.current.showModal()
  }

  const closeModal = () => {
    modalRef.current.closeModal()
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      showModal()
    },
    closeModal() {
      closeModal()
      setProduct(null)
    },
    setProduct(productSelected) {
      setProduct(productSelected)
    },
    isOpened() {
      return modalRef.current.isOpened()
    },
  }))

  return (
    <Modal ref={modalRef} padding={0} showCloseButton={false}>
      <ProductInfo product={product} closeModal={closeModal} />
    </Modal>
  )
})

export default ProductInfoModal
