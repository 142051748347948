import { UPDATE_USER } from "../constants"

const initialState = {
  user: {
    name: "",
    phoneNumber: "",
    currentAddress: null,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER: {
      const { user } = action
      return { ...state, user }
    }
    default:
      return state
  }
}
