/* eslint-disable no-undef */
import React, { Component } from "react"

class Form extends Component {
  state = {
    formSubmitted: false,
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({ formSubmitted: true })

    //  prevents spam
    if (event.target.sc.value !== "") {
      return
    }

    const data = new FormData(event.target)
    data.delete("sc")

    fetch(
      "https://script.google.com/macros/s/AKfycbwc0Bh6oqn5zr_6ZbNytUInZHbLJjrHQZTcVLZs14qjTYh7sjDQ/exec",
      {
        method: "POST",
        body: data,
      }
    )
  }

  render() {
    const { formName, children } = this.props
    const { formSubmitted } = this.state

    return (
      <form
        className="mx-5 lg:mx-40"
        onSubmit={this.handleSubmit}
        autoComplete="on"
        name="contact-form"
      >
        <div className="flex flex-col">
          <input name="sc" type="hidden" value="" />
          <input name="Formulário" type="hidden" value={formName} required />
          {children}
        </div>
        {formSubmitted ? (
          <div className="alert alert-success">
            Obrigado, em breve entraremos em contato.
          </div>
        ) : (
          <input
            className="button-sc-static px-20 py-3"
            name="submit"
            type="submit"
            value="ENVIAR"
          />
        )}
      </form>
    )
  }
}
export default Form
